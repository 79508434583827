<template>
  <FormPage
    collectionName="establishments"
    :tabs="tabs"
    :prevalidate="prevalidate"
    :id="$route.params.id"
    :formIsReadOnly="true"
    :headerData="{ establishment }"
    v-if="!!establishment && !!actions"
  >
    <template v-slot:formHeader="{ establishment }">
      <div class="vx-row">
        <div class="vx-col sm:w-1/1 w-full">
          <h1>
            <strong
              >{{ establishment.alias }} -
              {{ establishment.customer.alias }}</strong
            >
          </h1>
        </div>
        <vs-divider />
      </div>
    </template>
  </FormPage>
  <div class="custom-absolute-loading" v-else>
    <div class="loading">
      <div class="effect-1 effects"></div>
      <div class="effect-2 effects"></div>
      <div class="effect-3 effects"></div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { db } from "@/firebase/firebaseConfig";
import firebase from "firebase/app";

import FormPage from "@/components/penal-retail-helpers/FormPage";

let collectionName = "teaching";

export default {
  components: {
    FormPage,
  },
  computed: {
    ...mapGetters(["allCases"]),
  },
  created() {
    this.$bind(
      "establishment",
      db.collection("establishments").doc(this.$route.params.id),
      { wait: true }
    );
    this.$bind(
      "actions",
      db
        .collection(`establishments/${this.$route.params.id}/actions`)
        .orderBy("date"),
      { wait: true }
    );
  },
  data() {
    let readonly = ["customer", "supercustomer"].includes(
      this.$store.state.AppActiveUser.role
    );
    const establishmentId = this.$route.params.id;
    let attributes = [];
    let self = this;
    attributes.push({
      size: "1/1",
      type: "multiEdit",
      key: "actions",
      config: {
        columns: [
          { key: "date", type: "date", size: "1/4", validation: "required" },
          {
            key: "type",
            type: "select",
            size: "1/3",
            extra: { options: [{ id: "teaching", alias: "FORMACIÓN TIENDA" }] },
            readonly: true,
          },
          { key: "action", type: "input", size: "1/3" },
        ],
        readonly() {
          return readonly;
        },
        entities() {
          return self.actions;
        },
        prefix: `${collectionName}.actions`,
        async onAdd(model) {
          const currentUser = {
            id: self.$store.state.AppActiveUser.uid,
            email: self.$store.state.AppActiveUser.email,
          };

          const newAction = {
            date: firebase.firestore.Timestamp.fromDate(new Date()),
            createdAt: firebase.firestore.Timestamp.now(),
            customerId: model.customer.id,
            establishmentId,
            type: { id: "teaching", alias: "FORMACIÓN TIENDA" },
            createdBy: currentUser,
          };

          await db
            .collection(`/establishments/${establishmentId}/actions`)
            .add(newAction);
        },
        onRemove(id) {
          db.collection(`/establishments/${establishmentId}/actions`)
            .doc(id)
            .delete();
        },
        onChange(id, attribute, value) {
          let isChanged = true;
          if (attribute === "date" && !!value) {
            value = firebase.firestore.Timestamp.fromDate(new Date(value));
            //Caso especial de fecha que me llegan datos aunque no haya cambiado
            let oldValue = self.actions.find((a) => a.id === id)[attribute];
            isChanged = value.seconds !== oldValue.seconds;
          }

          if (isChanged) {
            let dataToUpdate = {
              updatedAt: firebase.firestore.Timestamp.now(),
              updatedBy: {
                email: self.$store.state.AppActiveUser.email,
                id: self.$store.state.AppActiveUser.uid,
              },
            };
            dataToUpdate[attribute] = value;

            db.collection(`/establishments/${establishmentId}/actions`)
              .doc(id)
              .update(dataToUpdate);
          }
        },
      },
    });
    let data = {
      establishment: null,
      actions: null,
      collectionName,
      tabs: [{ name: `establishments.tabs.general`, attributes }],
    };

    return data;
  },
  methods: {
    async prevalidate() {
      return false;
    },
  },
};
</script>
